<template>
  <div class="navbar-padding"></div>
</template>

<style scoped>
/* used with containers below navs */
.navbar-padding {
  /* nav-btn text heigh + 2* nav-btn padding + some additional space */
  padding-top: calc(1.5rem + 2 * 1.5rem);
  margin-bottom: 2rem;
  /*
  This little "hack" with colours allow to have the navbar
    look almost the same as the blur on background
    when the page has no hero.
  This is easier than having something
    to detect the presence of a hero.
  This is the background colour color picked with a semi transparent
    navbar on top.
  */
  background-color: #fbfbfc;
  border-bottom: 1px solid var(--seperator-colour);
}
</style>
